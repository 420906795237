import { useEffect, useState } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
  Select,
  Textarea,
} from '@snsw/react-component-library';
import { useFormik } from 'formik';
import { ClientOptions } from 'src/components/clients/types';
import { getClientOptions } from 'src/utils/clientUtils';
import getContent, {
  combineContent,
  getContentWithReplacements,
} from 'src/utils/contentUtils';
import * as Yup from 'yup';

import { StyledFormGroup, StyledMandatoryFieldsDescription } from './styles';

const validationSchema = Yup.object({
  entity: Yup.string().required('Entity selection is required'),
  description: Yup.string().required('Description is required'),
});

export const ArchiveToTrim = ({
  open,
  close,
  clientOptions,
  messageTrimTitleSegment,
  correspondenceTrimTitleSegment,
  modalType,
  onUpload,
  matterLeadClientId,
}: {
  open: boolean;
  close: () => void;
  clientOptions: ClientOptions;
  modalType: 'message' | 'correspondence';
  onUpload: (params: {
    entity: string;
    description: string;
    trimTitle: string;
  }) => void;
  correspondenceTrimTitleSegment?: string;
  messageTrimTitleSegment?: string;
  matterLeadClientId: number | null;
}) => {
  const [messageCharCount, setMessageCharCount] = useState(0);

  const {
    handleSubmit,
    submitCount,
    errors,
    values,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      entity: '',
      description: '',
      trimTitle: '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onUpload(values);
    },
  });

  useEffect(() => {
    let trimTitle = '';
    if (correspondenceTrimTitleSegment) {
      trimTitle = `${values.entity}-${correspondenceTrimTitleSegment}-correspondence-${values.description}`;
    }

    if (messageTrimTitleSegment) {
      trimTitle = `${values.entity}-${messageTrimTitleSegment}-${values.description}`;
    }

    setFieldValue('trimTitle', trimTitle);
  }, [
    correspondenceTrimTitleSegment,
    messageTrimTitleSegment,
    setFieldValue,
    values.description,
    values.entity,
  ]);

  useEffect(() => {
    if (matterLeadClientId) {
      setFieldValue('entity', `${matterLeadClientId}`);
    }
  }, [clientOptions, matterLeadClientId, setFieldValue]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent(
        `matter.upload.${modalType}.to.trim.heading` as keyof typeof combineContent,
      )}
      buttons={[
        {
          text: 'Upload',
          type: 'submit',
          onClick: handleSubmit,
        },
        {
          text: 'Cancel',
          onClick: () => {
            close();
            resetForm();
          },
        },
      ]}
      description={getContent('matter.upload.to.trim.description')}
    >
      <StyledMandatoryFieldsDescription>
        {getContent('matter.upload.to.trim.mandatory.fields.description')}
      </StyledMandatoryFieldsDescription>

      <Row>
        <Col span={8}>
          <FormGroup
            errorMessage={errors.entity}
            id="entity"
            label="Entity Name"
            hasError={submitCount > 0 && errors.entity}
          >
            {clientOptions && clientOptions.length > 1 ? (
              <Select
                name="entity"
                value={values.entity}
                onChange={handleChange}
                options={getClientOptions(clientOptions)}
              />
            ) : (
              <Input
                name="entity"
                value={
                  clientOptions &&
                  `${clientOptions[0].value} ${clientOptions[0].text}`
                }
                onChange={handleChange}
                inputWidth="xl"
                placeholder="Please enter entity name"
                disabled
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <StyledFormGroup id="trimTitle" label="Trim Title">
            <Textarea
              name="trimTitle"
              value={values.trimTitle}
              inputWidth="xl"
              disabled
            />
          </StyledFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            helpMessage={getContentWithReplacements(
              'matter.upload.to.trim.description.helpMessage',
              {
                remLength: (60 - messageCharCount).toString(),
              },
            )}
            errorMessage={errors.description}
            id="description"
            label="Description"
            hasError={submitCount > 0 && errors.description}
          >
            <Textarea
              name="description"
              value={values.description}
              onChange={(e) => {
                setMessageCharCount(e.target.value.length);
                handleChange(e);
              }}
              maxLength={60}
            />
          </FormGroup>
        </Col>
      </Row>
    </Modal>
  );
};
