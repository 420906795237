import { Modal } from '@snsw/react-component-library';
import getContent from 'src/utils/contentUtils';

interface ConfirmSubmitModalProps {
  open: boolean;
  close: () => void;
  handleConfirmSubmit: () => void;
}

export const ConfirmSubmitModal = ({
  open,
  close,
  handleConfirmSubmit,
}: ConfirmSubmitModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={getContent(
        'correspondence.customer.submitResponseToAuditCommencementsDocuments.confirmSubmit.modal.title',
      )}
      buttons={[
        {
          text: 'Confirm and send',
          onClick: handleConfirmSubmit,
        },
        {
          text: 'Cancel',
          onClick: close,
        },
      ]}
      description={getContent(
        'correspondence.customer.submitResponseToAuditCommencementsDocuments.confirmSubmit.modal.description',
      )}
    />
  );
};
