import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  ComponentLoader,
  ContentContainer,
  Row,
} from '@snsw/react-component-library';
import { ErrorSummary } from 'ams-common';
import { useFormik } from 'formik';
import { QueryKey } from 'src/api/constants';
import { useClients } from 'src/components/clients/hooks';
import { DocumentsTable } from 'src/components/correspondence/documentsTable';
import { DocumentUploadContainer } from 'src/components/correspondence/documentUploadContainer';
import { EntityNames } from 'src/components/correspondence/entityNames';
import { Message } from 'src/components/correspondence/message';
import { MessageSubject } from 'src/components/correspondence/messageSubject';
import { Recipient } from 'src/components/correspondence/recipient';
import DeleteDocument from 'src/components/deleteDocument';
import DocumentModal from 'src/components/documentModal';
import { useMatterDocuments } from 'src/components/documentTableWithAccordion/hooks';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { InvalidFormatUploadFailure } from 'src/components/fileUploadErrors';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import {
  useAuditorDetails,
  useCreateMessage,
  useDocumentDetails,
  useRecipients,
  useUserContext,
} from 'src/hooks';
import { getSelectedRecipientData } from 'src/hooks/recipients/helpers';
import { PATHS } from 'src/routes/constants';
import { UserLoginType } from 'src/types';
import { OperationType } from 'src/types/documentTypes';
import getContent from 'src/utils/contentUtils';
import { getValidInvalidFilesArray } from 'src/utils/fileUtils';

import { getCreateMessageRequestBody, getRecipientOptions } from './helpers';
import {
  ButtonGroup,
  StyledErrorContainer,
  StyledMessageContainer,
  StyledMessageHeading,
} from './styles';
import { EditDocument, MessagesInitValues } from './types';
import {
  auditorValidationSchema,
  customerValidationSchema,
} from './validation';

const initialValues: MessagesInitValues = {
  entities: '',
  recipient: '',
  messageSubject: '',
  message: '',
  documents: [],
};

export const NewMessage = () => {
  const navigate = useNavigate();
  const { matterClientId } = useMatter();
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [files, setFiles] = useState<File[] | null>();
  const [invalidFiles, setInvalidFiles] = useState<File[] | null>(null);
  const [openFilesUploadErrorModal, setOpenFilesUploadErrorModal] =
    useState<boolean>(false);
  const [selectedDocumentToEdit, setSelectedDocumentToEdit] =
    useState<string>('');
  const [editDocumentDetails, setEditDocumentDetails] =
    useState<EditDocument | null>(null);
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const [operationType, setOperationType] = useState<OperationType>(
    OperationType.Add,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDocumentIdToRemove, setSelectedDocumentIdToRemove] =
    useState<string>('');
  const [selectedDocumentNameToRemove, setSelectedDocumentNameToRemove] =
    useState<string>('');

  const [refetchDocumentDetailsToggle, setRefetchDocumentDetailsToggle] =
    useState(false);
  const errorSummaryRef = useRef<HTMLDivElement>(null);

  const { matterUId, matterId } = useMatter();
  const { clientOptions } = useClients(matterUId, isCustomer);
  const { data: recipientsData, isLoading: isRecipientDataLoading } =
    useRecipients(matterUId, isCustomer);
  const { data: documentsData, isLoading: matterDocumentsLoading } =
    useMatterDocuments(matterUId, isCustomer);
  const {
    data: documentDetailsToEdit,
    refetch: documentsDetailToEditRefetch,
    isLoading: isDocumentDetailsToEditLoading,
  } = useDocumentDetails(isCustomer, matterUId, selectedDocumentToEdit);
  const { data: auditorDetails, isLoading: isAuditorDetailsLoading } =
    useAuditorDetails(isCustomer);

  const onCreateMessageSuccess = (statusCode: number) => {
    if (statusCode === 201) {
      navigate(`${PATHS.matters}/${matterId}${PATHS.messages}`, {
        state: {
          sentMessageSuccess: 'Message sent successfully',
        },
      });
    }
  };

  const onCreateMessageError = () => {
    setErrorKeys((prevErrorKeys) => [
      ...new Set([
        ...prevErrorKeys,
        `${ScreenNames.MESSAGE_SEND}-${QueryKey.MESSAGE_DETAILS}-POST` as ErrorKey,
      ]),
    ]);
  };

  const { mutate: createMessage, isLoading: isCreateMessageLoading } =
    useCreateMessage(
      matterUId,
      isCustomer,
      onCreateMessageSuccess,
      onCreateMessageError,
    );

  const {
    values,
    setValues,
    submitCount,
    errors,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: isCustomer
      ? customerValidationSchema()
      : auditorValidationSchema(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      const { userId } = auditorDetails || {};
      const createMessageRequestBody = getCreateMessageRequestBody(values);
      // Incase of customer sending a new message auditor id needs to be included in recipientIds
      await createMessage([
        {
          ...createMessageRequestBody,
          ...(isCustomer && userId ? { recipientIds: [userId] } : {}),
        },
      ]);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDocumentToEdit) {
        return;
      }
      await documentsDetailToEditRefetch();
    };
    fetchData();
  }, [
    documentsDetailToEditRefetch,
    selectedDocumentToEdit,
    refetchDocumentDetailsToggle,
  ]);

  useEffect(() => {
    if (!documentDetailsToEdit) {
      return;
    }

    const { name, id, clients, classification, description } =
      documentDetailsToEdit;

    setEditDocumentDetails({
      documentName: name,
      documentId: id,
      clientId: clients.map(({ id }) => id).join(','),
      classification,
      description,
    });
  }, [documentDetailsToEdit, refetchDocumentDetailsToggle]);

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      errorSummaryRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (matterClientId) {
      setFieldValue('entities', `${matterClientId}`);
    }
  }, [matterClientId, setFieldValue]);

  useEffect(() => {
    if (!auditorDetails || !isCustomer) {
      return;
    }
    setFieldValue('recipient', `${auditorDetails.fullName}`);
  }, [auditorDetails, isCustomer, setFieldValue]);

  const recipientOptions = useMemo(
    () =>
      getRecipientOptions(
        getSelectedRecipientData(values.entities, recipientsData),
      ),
    [recipientsData, values.entities],
  );

  const handleFieldChange = ({
    value,
    field,
  }: {
    value: string;
    field: string;
  }) => {
    setValues({
      ...values,
      ...{ [field]: value },
    });
  };

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const { validFiles, invalidFiles } =
      getValidInvalidFilesArray(uploadedFiles);
    if (invalidFiles.length > 0) {
      setOpenFilesUploadErrorModal(true);
    } else if (invalidFiles.length === 0) {
      setOpenDocumentModal(true);
    }

    setInvalidFiles(invalidFiles);
    setFiles(validFiles);
  };

  const handleDocumentEdit = ({ documentId }: { documentId: string }) => {
    setSelectedDocumentToEdit(documentId);
    setOperationType(OperationType.Edit);
    setOpenDocumentModal(true);
    setRefetchDocumentDetailsToggle((prev) => !prev);
  };

  const handleCloseDeleteDocumentModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleAfterDocumentDeleteModal = (documentId?: string) =>
    setFieldValue('documents', [
      ...(values.documents ?? []).filter(
        ({ documentId: docId }) => docId !== documentId,
      ),
    ]);

  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
    setEditDocumentDetails(null);
    setOperationType(OperationType.Add);
    setOpenFilesUploadErrorModal(false);
    setInvalidFiles(null);
  };

  const handleRemoveDocument = ({
    documentId,
    documentName,
  }: {
    documentId: string;
    documentName: string;
  }) => {
    setIsDeleteModalOpen(true);
    setSelectedDocumentIdToRemove(documentId);
    setSelectedDocumentNameToRemove(documentName);
  };

  const handleFileUploadValue = ({
    fileName,
    docId,
    fileSize,
  }: {
    fileName: string;
    docId: string | null;
    fileSize: number;
  }) => {
    if (!docId) {
      return;
    }
    setFieldValue('documents', [
      ...(values.documents ?? []),
      ...[
        {
          documentId: docId,
          documentName: fileName,
          documentSize: fileSize,
        },
      ],
    ]);
  };

  return (
    <ContentContainer>
      <ComponentLoader
        active={
          isRecipientDataLoading ||
          matterDocumentsLoading ||
          isDocumentDetailsToEditLoading ||
          isCreateMessageLoading ||
          isAuditorDetailsLoading
        }
        fullPage
      />
      <ErrorHandler keys={errorKeys} />
      <Row>
        <Col span={8}>
          <StyledMessageHeading>
            {getContent('matter.messages.new.message.heading')}
          </StyledMessageHeading>
          <StyledMessageContainer>
            <span>{getContent('matter.messages.new.message.description')}</span>
          </StyledMessageContainer>
        </Col>
      </Row>
      <StyledErrorContainer
        ref={errorSummaryRef}
        showError={!isValid && submitCount > 0}
      />
      <ErrorSummary errors={errors} />
      <form>
        <Row>
          <Col span={8}>
            <EntityNames
              isCustomer={isCustomer}
              clientOptions={clientOptions}
              value={values.entities}
              hasError={!!(submitCount > 0 && errors.entities)}
              errorMessage={errors.entities}
              handleChange={handleFieldChange}
              label="Entity name(s)"
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Recipient
              isCustomer={isCustomer}
              recipientOptions={recipientOptions || []}
              hasError={!!(submitCount > 0 && errors.recipient)}
              errorMessage={errors.recipient}
              value={values.recipient}
              handleChange={handleFieldChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <MessageSubject
              hasError={!!(submitCount > 0 && errors.messageSubject)}
              errorMessage={errors.messageSubject}
              value={values.messageSubject}
              handleChange={handleFieldChange}
              placeholder="<<Message subject>>"
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Message
              value={values.message}
              errorMessage={errors.message}
              hasError={!!(submitCount > 0 && errors.message)}
              handleChange={handleFieldChange}
              maxLength={1000}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentUploadContainer
              handleFileUpload={handleFileUpload}
              isCustomer={isCustomer}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentsTable
              handleDocumentDelete={handleRemoveDocument}
              uploadedDocuments={values.documents}
              handleDocumentEdit={handleDocumentEdit}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>Send</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </form>
      {isDeleteModalOpen && (
        <DeleteDocument
          matterUId={matterUId}
          documentId={selectedDocumentIdToRemove}
          documentName={selectedDocumentNameToRemove}
          close={handleCloseDeleteDocumentModal}
          isOpen={isDeleteModalOpen}
          handleAfterDocumentDelete={handleAfterDocumentDeleteModal}
          isCustomer={isCustomer}
        />
      )}
      {openDocumentModal && !isDocumentDetailsToEditLoading && (
        <DocumentModal
          matterUId={matterUId}
          close={handleCloseDocumentModal}
          handleFileUploadValue={handleFileUploadValue}
          documents={files || null}
          clientOptions={clientOptions}
          matterId={matterId}
          documentsData={documentsData}
          operationType={operationType}
          editDocumentDetails={editDocumentDetails}
          selectedCustomer={values.entities}
        />
      )}
      <InvalidFormatUploadFailure
        open={openFilesUploadErrorModal}
        close={() => setOpenFilesUploadErrorModal(false)}
        onContinue={() => {
          if (files && files?.length > 0) {
            setOpenDocumentModal(true);
          } else {
            setOpenFilesUploadErrorModal(false);
          }
        }}
        invalidFiles={invalidFiles || []}
        validFiles={files || []}
      />
    </ContentContainer>
  );
};
