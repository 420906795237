import { ClientOptions } from 'src/components/clients/types';
import {
  CLASSIFICATION_OPTIONS_AUDITOR,
  CLASSIFICATION_OPTIONS_CUSTOMER,
} from 'src/constants';
import { ClassificationOptions } from 'src/hooks/types';
import { OperationType } from 'src/types/documentTypes';

import { MatterDocuments } from '../documentTableWithAccordion/types';

import { DocumentNameWithClassification, ModalData } from './types';

const getAllCustomers = (clientOptions: ClientOptions) =>
  clientOptions?.map(({ value }) => `${value}`).join(',') || '';

const getApiClassificationName = (
  selectedClassification: string,
  isCustomer: boolean,
) =>
  (isCustomer
    ? CLASSIFICATION_OPTIONS_CUSTOMER
    : [...CLASSIFICATION_OPTIONS_AUDITOR, ...CLASSIFICATION_OPTIONS_CUSTOMER]
  ).find((option) => String(option.value) === selectedClassification)
    ?.apiSectionName || '';

const getClients = (customers: string) =>
  customers
    .replace(/^,/, '')
    .split(',')
    .map((customer) => ({ id: customer }));

export const constructRequestBody = (
  matterId: string,
  s3Key: string,
  fileName: string,
  modalData: ModalData,
  clientOptions: ClientOptions,
  isCustomer: boolean,
) => {
  const { customer, classification, description } = modalData;
  const customers =
    customer === 'all' ? getAllCustomers(clientOptions) : customer;

  return [
    {
      name: fileName,
      matterId,
      s3Key,
      clients: getClients(customers),
      classification: getApiClassificationName(classification, isCustomer),
      description,
    },
  ];
};

const getCustomersToMatch = (
  customers: string,
  clientOptions: ClientOptions,
) => {
  const filteredCustomers =
    customers !== 'all' ? customers : getAllCustomers(clientOptions);
  return filteredCustomers
    .split(',')
    .map((str) => (str === '' ? NaN : Number(str)))
    .filter((num) => !Number.isNaN(num));
};

export const getDocumentNamesWithClassification = (
  documentId: string,
  documentData: MatterDocuments,
  customers: string,
  clientOptions: ClientOptions,
): DocumentNameWithClassification[] => {
  const matchedCustomers = getCustomersToMatch(customers, clientOptions);

  return documentData
    .filter(
      ({ id, clients }) =>
        id !== documentId &&
        clients.some(({ id: cId }) => matchedCustomers.includes(cId)),
    )
    .map(({ name, classification }) => ({ name, classification }));
};

export const getClassificationText = (
  classificationOptions: ClassificationOptions[],
  selectedClassification: string,
) =>
  classificationOptions.find(({ value }) => value === selectedClassification)
    ?.text;

export const getClassificationTextFromApiSectionName = (
  classificationOptions: ClassificationOptions[],
  classificationFromApi: string,
) =>
  classificationOptions.find(
    ({ apiSectionName }) => classificationFromApi === apiSectionName,
  )?.text || classificationFromApi;

export const getClassificationValueFromApiSectionName = (
  classificationOptions: ClassificationOptions[],
  classificationFromApi: string,
) =>
  classificationOptions.find(
    ({ apiSectionName, text }) =>
      classificationFromApi === apiSectionName ||
      classificationFromApi === text,
  )?.value || classificationFromApi;

export const checkIfFileAlreadyUploaded = (
  uploadedDocuments: DocumentNameWithClassification[],
  documentName: string,
  classificationOptions: ClassificationOptions[],
  selectedClassification?: string,
) =>
  uploadedDocuments.some(
    ({ name, classification }) =>
      name === documentName &&
      getClassificationTextFromApiSectionName(
        classificationOptions,
        classification,
      ) === selectedClassification,
  );

export const getClientOptions = (customers: ClientOptions) => {
  if (!customers) {
    return [];
  }

  return [
    ...customers.map(({ text, value }) => ({
      text: `${value} ${text}`,
      value,
    })),
    { text: 'Applicable to all customers', value: 'all' },
  ];
};

export const getEditDocumentRequestBody = (
  modalData: ModalData,
  clientOptions: ClientOptions,
  isCustomer: boolean,
) => {
  const { customer, classification, description } = modalData;
  const customers =
    customer === 'all' ? getAllCustomers(clientOptions) : customer;

  return {
    classification: getApiClassificationName(classification, isCustomer),
    description,
    clients: getClients(customers),
  };
};

export const getActualCustomers = (customers?: string) => {
  if (!customers) {
    return;
  }
  const [firstCustomer, ...otherCustomers] = customers.split(',');
  // eslint-disable-next-line consistent-return
  return otherCustomers.length ? customers.split(',') : firstCustomer;
};

export const getUpdatedCustomers = (
  clientOptions: ClientOptions,
  customers?: string,
) => {
  if (!customers) {
    return;
  }

  if (customers === 'all') {
    // eslint-disable-next-line consistent-return
    return clientOptions?.map(({ value }) => value);
  }

  const [firstCustomer, ...otherCustomers] = customers.split(',');
  // eslint-disable-next-line consistent-return
  return otherCustomers.length ? customers.split(',') : firstCustomer;
};

export const checkIfActualCustomersEqualsToUpdatedCustomers = (
  actualCustomers?: string[] | string,
  editedCustomers?: string[] | string,
) => {
  if (!actualCustomers || !editedCustomers) {
    return false;
  }

  if (Array.isArray(actualCustomers) && Array.isArray(editedCustomers)) {
    const sortedActualCustomers = [...actualCustomers].sort();
    const sortedEditedCustomers = [...editedCustomers].sort();
    return (
      JSON.stringify(sortedActualCustomers) ===
      JSON.stringify(sortedEditedCustomers)
    );
  }

  return actualCustomers === editedCustomers;
};

export const getClassificationOptions = (
  isCustomer: boolean,
  operationType: OperationType,
): ClassificationOptions[] => {
  if (!isCustomer) {
    if (operationType === OperationType.Edit) {
      const combinedClassifications = [
        ...CLASSIFICATION_OPTIONS_AUDITOR,
        ...CLASSIFICATION_OPTIONS_CUSTOMER,
      ];
      const uniqueClassificationOptionsMap = new Map<
        string,
        ClassificationOptions
      >();

      combinedClassifications.forEach((classification) => {
        if (!uniqueClassificationOptionsMap.has(classification.text)) {
          uniqueClassificationOptionsMap.set(
            classification.text,
            classification,
          );
        }
      });

      return Array.from(uniqueClassificationOptionsMap.values()).sort(
        (a, b) => {
          if (a.text === 'Other') return 1;
          if (b.text === 'Other') return -1;
          return a.text.localeCompare(b.text);
        },
      );
    }

    return [...CLASSIFICATION_OPTIONS_AUDITOR];
  }
  return [...CLASSIFICATION_OPTIONS_CUSTOMER];
};
