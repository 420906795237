import { Link } from 'react-router-dom';
import {
  Accordion,
  Card,
  FormGroup,
  IconEdit,
  TableRow,
} from '@snsw/react-component-library';
import { colours, fontWeight, pxToRem, spacing, StickyTable } from 'ams-common';
import styled, { css } from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  section {
    padding: 0;
  }
`;

export const StyledTableRow = styled(TableRow)``;

export const StyledStickyTable = styled(StickyTable)`
  width: 100% !important;
  th:first-child,
  td:first-child {
    width: 30%;
  }
  td {
    border-right: none !important;
  }
`;

export const NoStyledButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  cursor: pointer;
`;

export const Status = styled.div<{
  status: string;
}>`
  color: ${({ status }) => {
    switch (status) {
      case 'Open':
        return `${colours.text.link}`;
      case 'Draft':
        return `${colours.text.link}`;
      default:
        return `${colours.neutral.dark}`;
    }
  }};
`;

export const RowWithButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CorrespondenceCardRow = styled.div`
  margin-bottom: ${spacing.sm};
`;

export const DocumentLink = styled.a`
  margin-left: ${spacing.sm};
  font-weight: 700;
`;

export const StyledEditIcon = styled(IconEdit)`
  margin-left: ${spacing.sm};
  margin-right: 5px;
`;

export const NoStyleButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: ${colours.text.link};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-decoration-thickness: 1px;
`;

export const FormDateGroup = styled(FormGroup)<{
  hasError: boolean;
}>`
  label {
    display: none;
  }

  fieldset {
    label {
      display: block;
    }

    h3 {
      font-size: 110%;
      font-weight: 500;
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      input,
      select {
        border-color: ${colours.borders.error};
      }
    `}
`;
export const StyledCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};
  border-top: 5px solid ${colours.primary.light};
  height: fit-content;
  align-items: flex-end;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const CorrespondenceType = styled.p`
  color: ${colours.text.link};
`;

export const ListContainer = styled.div`
  width: 70%;
`;
export const ButtonContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: ${spacing.md};
  gap: ${spacing.md};
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: ${spacing.sm};
  button {
    margin-top: ${spacing.sm};
  }
`;

export const StyledArchiveToTrimText = styled.span`
  padding: ${spacing.sm};
  font-weight: ${fontWeight.bold};
`;

export const StyledArchivedToTrimText = styled.div`
  padding-top: ${spacing.sm};
  font-weight: ${fontWeight.bold};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFileUploadContainer = styled.div`
  margin-top: ${spacing.lg};
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
`;

export const StyledIconCell = styled.div`
  color: ${colours.text.link};
  display: flex;
  align-items: center;
  gap: ${spacing.xs};
  cursor: pointer;

  svg {
    fill: ${colours.text.link};
    width: ${pxToRem(spacing.sm)};
    height: ${pxToRem(spacing.sm)};
    flex-shrink: 0;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  flex: 10;
`;

export const StyledMandatoryFieldsDescription = styled.span`
  margin-top: ${spacing.xs};
  display: flex;
  color: ${colours.text.disabled};
`;

export const StyledTooltipText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFilterWrapper = styled.div`
  position: relative;
  top: -40px;
`;
