import * as t from 'io-ts';
import { tOptional } from 'src/types';

export const Message = t.intersection([
  t.type({
    portalMatterId: t.string,
    correspondenceId: t.string,
    correspondenceThreadId: t.string,
    corroType: t.string,
  }),
  t.partial({
    leadClientName: t.string,
    matterId: t.string,
    auditorName: t.string,
  }),
]);

export const ActionItem = t.intersection([
  t.type({
    id: t.string,
    active: t.boolean,
    message: Message,
    userId: t.string,
    dueDate: tOptional(t.string),
    actionItemStatus: t.string,
  }),
  t.partial({
    customerResponseDate: t.string,
  }),
]);

export const ActionItemsListCodec = t.array(ActionItem);

export const ActionItems = t.array(ActionItem);
export type ActionItemsList = t.TypeOf<typeof ActionItems>;

export type InternalDueDatePayload = {
  internalDueDate: string;
};
