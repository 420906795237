import classificationSectionsContent from './classificationSections/contents';

const contents = {
  ...classificationSectionsContent,
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.heading':
    'Submit response to audit commencement documents',
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.message.helpMessage.text':
    'Maximum 3000 characters including spaces. {remLength} character(s) remaining',
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.confirmSubmit.modal.title':
    'Confirm and send response',
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.confirmSubmit.modal.description':
    'Your response will be submitted. The uploaded document(s) can no longer be removed.',
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.saveAsDraft.modal.title':
    'Save as draft',
  'correspondence.customer.submitResponseToAuditCommencementsDocuments.saveAsDraft.modal.description':
    'A draft for audit commencement correspondence response will be saved in correspondence. Until the final submission is made, your response shall not be viewed by the case officer.',
};

export default contents;
