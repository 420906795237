/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  ComponentLoader,
  FormGroup,
  Heading,
  Row,
  Select,
  StatusLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@snsw/react-component-library';
import { formatDate } from 'ams-common';
import { MethodTypes } from 'src/api';
import { QueryKey } from 'src/api/constants';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useUserContext } from 'src/hooks';
import { PATHS } from 'src/routes/constants';
import { UserLoginType } from 'src/types';
import getContent from 'src/utils/contentUtils';

import {
  AUDITOR_ACTION_ITEM_TYPE_MAPPER,
  CUSTOMER_ACTION_ITEM_TYPE_MAPPER,
  DUE_DATE_STATUS_MAPPER,
} from '../constants';
import { useAuditorActionItems, useUpdateInternalDueDate } from '../hooks';
import { ActionItemsList } from '../types';

import { ActionItemsDueDateButton } from './ActionItemsDueDateButton';
import { getAllActionItems, totalActionItems } from './helpers';
import {
  StyledContainer,
  StyledLinkContainer,
  StyledNoDueDateContainer,
  StyledTableCell,
  StyledTableCellB,
  StyledTableContainer,
} from './styles';

export const ActionItemsTable = ({
  handleErrors,
}: {
  handleErrors: (errorKeys: ErrorKey[]) => void;
}) => {
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const [selectedValue, setSelectedValue] = useState<string>();
  const [errors, setErrors] = useState<ErrorKey[]>([]);
  const { updateMatterCorrespondenceThreadId, updateMatterUId } = useMatter();
  const {
    data: actionItemsData,
    isLoading: isActionItemsDataLoading,
    isError: isActionItemsError,
  } = useAuditorActionItems();
  const [options, setOptions] = useState<{ text: string; value: string }[]>();
  const [actionItems, setActionItems] = useState<{
    [key: string]: ActionItemsList;
  }>();

  const {
    mutate: updateInternalDueDate,
    isError: isUpdateInternalDueDateError,
  } = useUpdateInternalDueDate();

  const actionItemsWrapper = isCustomer
    ? CUSTOMER_ACTION_ITEM_TYPE_MAPPER
    : AUDITOR_ACTION_ITEM_TYPE_MAPPER;

  useEffect(() => {
    setActionItems(
      actionItemsData &&
        actionItemsData.reduce((acc, val) => {
          if (val.message.matterId) {
            acc[val.message.matterId] = acc[val.message.matterId] || [];
            acc[val.message.matterId].push(val);
            return acc;
          }

          return acc;
        }, {}),
    );
  }, [actionItemsData]);

  useEffect(() => {
    const auditMatterFilterOptions =
      actionItems &&
      Object.keys(actionItems).map((key) => {
        return {
          text: `${key} - ${actionItems[key][0].message.leadClientName}`,
          value: `${key}`,
        };
      });
    setOptions(auditMatterFilterOptions);
  }, [actionItems]);

  useEffect(() => {
    if (isActionItemsError)
      setErrors([`${ScreenNames.DASHBOARD}-${QueryKey.ACTION_ITEMS}-GET`]);
  }, [isActionItemsError]);

  useEffect(() => {
    if (isUpdateInternalDueDateError)
      setErrors([`${ScreenNames.DASHBOARD}-${QueryKey.ACTION_ITEMS}-PATCH`]);
  }, [isUpdateInternalDueDateError]);

  useEffect(() => {
    handleErrors(errors);
  }, [errors, handleErrors]);

  if (actionItemsData && actionItemsData.length === 0) {
    return null;
  }

  const handleDueDateChange = async (
    matterUId: string,
    actionItemId: string,
    values: { internalDueDate: string },
    onError: () => void,
    onSuccess: () => void,
  ) => {
    await updateInternalDueDate(
      [matterUId, actionItemId, values, MethodTypes.PATCH],
      { onSuccess: () => onSuccess(), onError: () => onError() },
    );
  };

  return (
    <>
      <Heading level={3}>
        {getContent('matters.actionItemTable.title.text')}
      </Heading>
      <ComponentLoader active={isActionItemsDataLoading} fullPage />

      {options && actionItems ? (
        <>
          <Row>
            <Col span={5}>
              <FormGroup
                label={getContent('matters.actionItemTable.label.text')}
              >
                <Select
                  name="selectedValue"
                  value={selectedValue}
                  options={options}
                  onChange={(e) => setSelectedValue(e.target.value ?? '')}
                  placeholder="All matters"
                />
              </FormGroup>
            </Col>
          </Row>

          <StyledTableContainer
            title={`${
              selectedValue
                ? actionItems[selectedValue].length
                : totalActionItems(actionItems)
            } ${getContent('matters.actionItemTable.subtitle.text')}`}
            id="actionItemTable"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>
                    {getContent(
                      'matters.actionItemTable.header.actionItem.text',
                    )}
                  </TableHeader>
                  <TableHeader>
                    {getContent(
                      'matters.actionItemTable.header.customerResponseDate.text',
                    )}
                  </TableHeader>
                  <TableHeader />
                  <TableHeader>
                    {getContent('matters.actionItemTable.header.dueDate.text')}
                  </TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>

              {(selectedValue
                ? actionItems[selectedValue]
                : getAllActionItems(actionItems)
              ).map(
                ({
                  dueDate,
                  message,
                  id,
                  actionItemStatus,
                  customerResponseDate,
                }) => (
                  <TableBody key={id}>
                    <TableRow>
                      <TableCell>
                        <span>
                          <b>
                            {getContent(
                              'matters.actionItemTable.actionItem.prefix.text',
                            )}
                          </b>
                        </span>
                        <b>
                          <Link
                            to={`${PATHS.matters}/${message.matterId}${PATHS.threads}/${message.correspondenceThreadId}`}
                            onClick={() => {
                              updateMatterCorrespondenceThreadId(
                                message.correspondenceThreadId,
                              );
                              updateMatterUId(message.portalMatterId);
                            }}
                          >
                            {actionItemsWrapper[message.corroType]}
                            {getContent(
                              'matters.actionItemTable.actionItem.suffix.text',
                            )}
                          </Link>
                        </b>

                        <StyledLinkContainer>
                          <span>
                            {getContent(
                              'matters.actionItemTable.matterId.label.text',
                            )}
                          </span>
                          <Link
                            to={`${PATHS.matters}/${message.matterId}${PATHS.threads}/${message.correspondenceThreadId}`}
                            onClick={() => {
                              updateMatterCorrespondenceThreadId(
                                message.correspondenceThreadId,
                              );
                              updateMatterUId(message.portalMatterId);
                            }}
                          >
                            {message.matterId}
                          </Link>
                          <span>
                            <Link
                              to={`${PATHS.matters}/${message.matterId}${PATHS.threads}/${message.correspondenceThreadId}`}
                              onClick={() => {
                                updateMatterCorrespondenceThreadId(
                                  message.correspondenceThreadId,
                                );
                                updateMatterUId(message.portalMatterId);
                              }}
                            >
                              {message.leadClientName}
                            </Link>
                          </span>
                        </StyledLinkContainer>
                      </TableCell>
                      <TableCell>
                        <span>
                          {customerResponseDate
                            ? formatDate(customerResponseDate, 'DD MMMM YYYY')
                            : null}
                        </span>
                      </TableCell>
                      <StyledTableCell>
                        <span>
                          {dueDate && actionItemStatus !== 'UPCOMING' ? (
                            <StatusLabel
                              text={DUE_DATE_STATUS_MAPPER[actionItemStatus]}
                              variant={
                                actionItemStatus === 'OVERDUE'
                                  ? 'error'
                                  : 'info'
                              }
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </StyledTableCell>
                      <StyledTableCellB>
                        <StyledContainer>
                          <div>
                            {dueDate ? (
                              formatDate(dueDate.toString(), 'DD MMMM YYYY')
                            ) : (
                              <StyledNoDueDateContainer>
                                Due date required
                              </StyledNoDueDateContainer>
                            )}
                          </div>
                        </StyledContainer>
                      </StyledTableCellB>
                      <StyledTableCellB>
                        <StyledContainer>
                          {/* // eslint-disable-next-line no-nested-ternary */}
                          {message.correspondenceThreadId ? (
                            <ActionItemsDueDateButton
                              matterUId={message.portalMatterId}
                              dueDate={dueDate || ''}
                              actionItemId={id}
                              handleDueDateChange={handleDueDateChange}
                              corroType={message.corroType}
                            />
                          ) : null}
                        </StyledContainer>
                      </StyledTableCellB>
                    </TableRow>
                  </TableBody>
                ),
              )}
            </Table>
          </StyledTableContainer>
        </>
      ) : (
        ''
      )}
    </>
  );
};
