import { ReactNode, useEffect, useState } from 'react';
import {
  Col,
  FormDate,
  FormGroup,
  Input,
  Row,
  Textarea,
} from '@snsw/react-component-library';
import { MultiSelect, Tooltip } from 'ams-common';
import { useFormikContext } from 'formik';
import { useClients } from 'src/components/clients/hooks';
import { useMatter } from 'src/context/MatterContext';
import getContent, { getContentWithReplacements } from 'src/utils/contentUtils';

import { FormDateGroup } from './styles';
import { SubmitAuditCommencementDocumentsInitValues } from './types';

export const DetailsFrom = ({
  recipientOptions,
}: {
  recipientOptions: { text: ReactNode; value: string }[] | null;
}) => {
  const { matterUId } = useMatter();

  const { values, handleChange, errors, handleBlur, setValues, submitCount } =
    useFormikContext<SubmitAuditCommencementDocumentsInitValues>();
  const { clientOptions } = useClients(matterUId);
  const [messageCharCount, setMessageCharCount] = useState(0);

  const handleDueDateChange = ({ value }: { value: string }) => {
    setValues({
      ...values,
      ...{ dueDate: value },
    });
  };

  useEffect(() => {
    setMessageCharCount(values.message?.length || 0);
  }, [values.message]);

  return (
    <>
      <Row>
        <Col span={8}>
          <FormGroup
            errorMessage={errors.customer}
            id="customer"
            label={
              <Tooltip
                label="Correspondence relates to"
                text={getContent(
                  'matters.correspondence.customer.tooltip.text',
                )}
              />
            }
            hasError={submitCount > 0 && errors.customer}
          >
            {clientOptions && clientOptions.length > 1 ? (
              <MultiSelect
                inputWidth="xl"
                name="customer"
                value={values.customer}
                showSelectAll
                options={clientOptions}
                onChange={handleChange('customer')}
                onBlur={handleBlur}
                singleSelectionLabel="customer"
                multipleSelectionLabel="customers"
              />
            ) : (
              <Input
                name="customer"
                value={
                  clientOptions &&
                  `${clientOptions[0].value} ${clientOptions[0].text}`
                }
                inputWidth="xl"
                placeholder="Please enter customer"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormGroup
            errorMessage={errors.recipient}
            id="recipient"
            label="Recipient"
            hasError={submitCount > 0 && errors.recipient}
          >
            <MultiSelect
              name="recipient"
              inputWidth="xl"
              value={values.recipient}
              options={recipientOptions || []}
              onChange={handleChange('recipient')}
              onBlur={handleBlur}
              hasError={submitCount > 0 && errors.recipient}
              singleSelectionLabel="recipient"
              multipleSelectionLabel="recipients"
              showSelectAll
              selectAllLabel="Select all recipients"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormDateGroup
            id="dueDate"
            hasError={!!(submitCount > 0 && errors.dueDate)}
            errorMessage={errors.dueDate}
            label={
              <Tooltip
                label="Due date"
                text={getContent(
                  'matters.correspondence.submitAuditCommencementDocuments.dueDate.tooltip.text',
                )}
              />
            }
          >
            <FormDate value={values.dueDate} onChange={handleDueDateChange} />
          </FormDateGroup>
          <Input
            name="dueDate"
            style={{ position: 'absolute', left: '-9999px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormGroup
            errorMessage={errors.subject}
            id="subject"
            label="Message subject"
            hasError={submitCount > 0 && errors.subject}
          >
            <Input
              name="subject"
              value={values.subject}
              inputWidth="xl"
              placeholder="Please enter message subject"
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={100}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormGroup
            helpMessage={
              <span>
                {getContentWithReplacements(
                  'matters.correspondence.message.helpMessage.text',
                  {
                    maxLength: (3000).toString(),
                    remLength: (3000 - messageCharCount).toString(),
                  },
                )}
              </span>
            }
            errorMessage={errors.message}
            id="message"
            label="Message"
            hasError={submitCount > 0 && errors.message}
          >
            <Textarea
              name="message"
              value={values.message}
              inputWidth="xxl"
              onChange={(e) => {
                setMessageCharCount(e.target.value.length);
                handleChange(e);
              }}
              placeholder="Please enter a message"
              maxLength={3000}
              onBlur={handleBlur}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
