const content = {
  'contact.auditor.list.label.name': 'Name',
  'contact.auditor.list.label.position': 'Position',
  'contact.auditor.list.label.phone': 'Phone',
  'contact.auditor.list.label.email': 'Email',
  'contact.auditor.manager.heading.text': 'Case manager',
  'contact.auditor.heading.text': 'Case officer',
  'contact.auditor.OOOmessage.text': 'Out of office message',
  'contact.auditor.manager.text':
    'For any escalations please contact audit manager {managerName} at {managerEmail}',
  'contact.auditor.outOfOffice.text':
    'Your audit contact is out of office from {outOfOfficeFrom} to {outOfOfficeTo}',
};

export default content;
