const content = {
  'status.manager.heading.title': 'Status manager',
  'status.manager.mandatory.fields.description':
    'All fields must be completed, unless marked optional.',
  'status.manager.subheading.title': 'Current status',
  'status.manager.form.field.label1': 'Status to be updated',
  'status.manager.form.field.label2': 'Comment',
  'matters.status.manager.comment.helpmessage.text':
    'Maximum 250 characters including spaces. {remLength} character(s) remaining',
  'status.manager.modal.title': 'Confirm the status movement',
  'status.manager.modal.description':
    'The status of the audit matter will be moved from "{currentStatus}" to "{updatedStatus}". Please confirm.',
  'status.manager.modal.button.primary': 'Confirm',
  'status.manager.modal.button.secondary': 'Cancel',
};

export default content;
